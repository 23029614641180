import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle `

    html body {
        margin: 0;
        padding: 0;
        font-family: 'Exo 2', sans-serif;
    }

`;